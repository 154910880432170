import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'thinking',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'sign-in-email',
    loadChildren: () => import('./sign-in-email/sign-in-email.module').then( m => m.SignInEmailPageModule)
  },
  {
    path: 'thinking',
    loadChildren: () => import('./thinking/thinking.module').then( m => m.ThinkingPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'error-logs',
    loadChildren: () => import('./error-logs/error-logs.module').then( m => m.ErrorLogsPageModule)
  },
  {
    path: 'page-visits',
    loadChildren: () => import('./page-visits/page-visits.module').then( m => m.PageVisitsPageModule)
  },
  {
    path: 'error-logs-user/:userId',
    loadChildren: () => import('./error-logs-user/error-logs-user.module').then( m => m.ErrorLogsUserPageModule)
  },
  {
    path: 'error-logs-detailed',
    loadChildren: () => import('./error-logs-detailed/error-logs-detailed.module').then( m => m.ErrorLogsDetailedPageModule)
  },
  {
    path: 'page-visits-user/:userId',
    loadChildren: () => import('./page-visits-user/page-visits-user.module').then( m => m.PageVisitsUserPageModule)
  },
  {
    path: 'dashboard-online/:selectedEnv',
    loadChildren: () => import('./dashboard-online/dashboard-online.module').then( m => m.DashboardOnlinePageModule)
  },
  {
    path: 'view-user/:userId',
    loadChildren: () => import('./view-user/view-user.module').then( m => m.ViewUserPageModule)
  },
  {
    path: 'set-api',
    loadChildren: () => import('./set-api/set-api.module').then( m => m.SetApiPageModule)
  },
  {
    path: 'shout-incomplete',
    loadChildren: () => import('./shout-incomplete/shout-incomplete.module').then( m => m.ShoutIncompletePageModule)
  },
  {
    path: 'shout-incomplete-details/:shoutid/:selectedEnv',
    loadChildren: () => import('./shout-incomplete-details/shout-incomplete-details.module').then( m => m.ShoutIncompleteDetailsPageModule)
  },
  {
    path: 'shout-incomplete-view',
    loadChildren: () => import('./shout-incomplete-view/shout-incomplete-view.module').then( m => m.ShoutIncompleteViewPageModule)
  },
  {
    path: 'errors-by-code',
    loadChildren: () => import('./errors-by-code/errors-by-code.module').then( m => m.ErrorsByCodePageModule)
  },
  {
    path: 'errors-by-code-code/:id',
    loadChildren: () => import('./errors-by-code-code/errors-by-code-code.module').then( m => m.ErrorsByCodeCodePageModule)
  },
  {
    path: 'errors-by-code-details',
    loadChildren: () => import('./errors-by-code-details/errors-by-code-details.module').then( m => m.ErrorsByCodeDetailsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
