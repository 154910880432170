import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

import { initializeApp } from 'firebase/app';
import { Auth, createUserWithEmailAndPassword, getAuth, indexedDBLocalPersistence, initializeAuth, sendEmailVerification, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { Database, getDatabase } from 'firebase/database';

import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

import { Subject } from 'rxjs';
import { RegisterService } from '../register/register.service';
import { ProfileService } from '../profile/profile.service';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private readonly database: Database;
  private readonly auth: Auth;

  /** My global profile */
  public myProfile;
  /** My global Shout profile */
  public myShoutProfile;

  /** Where should we navigate after login? */
  targetPath: string;

  /** Are we going to need to link the logins? */
  shouldLinkLogins: boolean = false;
  /** User to link logins */
  registerUserRes: any;
  /** Usre login details form */
  detailsForm;
  /** Currency Code */
  myCurrencyCode: string;
  /** Did the currency code change? */
  currencyCodeChange: Subject<string> = new Subject<string>();

  constructor(
    private router: Router,
    private alertController: AlertController,
    private storageService: StorageService,
    private profileService: ProfileService,
    private registerService: RegisterService,

  ) {

    this.targetPath = JSON.parse(JSON.stringify(window.location.pathname));
    const firebaseApp = initializeApp(environment.firebaseConfig);

    if (Capacitor.isNativePlatform()) {
      initializeAuth(firebaseApp, {
        persistence: indexedDBLocalPersistence
      });
    }

    this.database = getDatabase(firebaseApp);
    this.auth = getAuth(firebaseApp);

  }

  startListeningToAuthStateChange() {

    // FirebaseAuthentication.addListener('authStateChange', this.authStateChanged())
    const auth = getAuth();
    auth.onAuthStateChanged(async (user) => {

      console.log('^^^^^^')
      console.log('Auth State Changed: ', user);
      console.log('^^^^^^')

      // Check if the phone number login triggered this: 
      if (user && user.providerData && user.providerData.length == 1 && user.providerData[0].providerId == "phone") {

      }
      else if (user && !user.emailVerified) {
        this.storageService.firebaseUser = user;
        this.storageService.token = await user.getIdToken();
      }
      else if (user && user.emailVerified) {
        console.log('1')
        this.storageService.firebaseUser = user;
        this.storageService.token = await user.getIdToken();

        // Check if the Firebase user is valid
        this.registerService.loginUser(user.email).subscribe(loginUserRes => {
          console.log('2')
          this.storageService.uniTaskrUser = loginUserRes;

          this.profileService.getProfile().subscribe(myProfileRes => {
            console.log('3')
            this.myCurrencyCode = myProfileRes.currencyCode ? myProfileRes.currencyCode : this.myCurrencyCode;
            this.currencyCodeChange.next(this.myCurrencyCode);
            this.myProfile = myProfileRes;

            // this.presenceService.setPresence("online");
            if (this.targetPath == "/thinking" || 
                this.targetPath == "/landing" || 
                this.targetPath == "/" ||
                this.targetPath == "/sign-in-email"
                ) {
                  console.log('4')
              this.targetPath = undefined;
            }

            if (this.targetPath == '/logout') {
              console.log('5')
              signOut(auth).then(() => {
                this.router.navigate(['/landing']);
              }).catch((error) => {
                console.log('Did not sign out');
              });
            }

            if (this.targetPath) {
              console.log('6')
              this.router.navigate([`${this.targetPath}`]);
            } else {
              console.log('7')
              this.router.navigate(['/dashboard']);
            }
          }, err => {
            console.log('myProfileRes ERROR: ', err);
            
            this.loginError();
          })

        }, err => {
          console.log('Error logging in: ', err);
          if (this.targetPath == "/sign-in-email") {
            this.targetPath = undefined;
          }
          this.router.navigate([`${this.targetPath ? this.targetPath : '/landing'}`]);
        })
      } else {
        console.log('8')
        if (this.targetPath && this.targetPath.split('/')[1] == 'reset-password-legacy') {
          if (this.targetPath == "/sign-in-email") {
            console.log('9')
          } else {
            this.router.navigate([`${this.targetPath ? this.targetPath : '/landing'}`]);
          }
        } else {
          console.log('10')
          if (this.targetPath == "/sign-in-email") {
            // this.targetPath = undefined;
          } else {
            this.router.navigate([`${this.targetPath ? this.targetPath : '/landing'}`]);
          }
        }
      }
    })
  }

  async getCurrentUser() {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  }

  async getIdToken() {
    const { user } = await FirebaseAuthentication.getCurrentUser();
    if (!user) {
      return null;
    }
    const { token } = await FirebaseAuthentication.getIdToken({ forceRefresh: true });
    return token;
  }

  createUserWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  sendVerificationEmail() {
    let user = this.auth.currentUser;
    return sendEmailVerification(user);
  }

  async checkUserExists(email: string, password?: string) {
    // const auth = getAuth();
    return signInWithEmailAndPassword(this.auth, email, password ? password : '');
  }

  loginUserWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  async loginError() {
    const alert = await this.alertController.create({
      header: 'Whoops!',
      message: 'Unable to login, please try again.',
      buttons: ['OK']
    });
    await alert.present();
    this.router.navigate(['/logout']);
  }

  /**
   * 
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithFacebook() {
    const result = await FirebaseAuthentication.signInWithFacebook();
    return result;
  };

  /**
   * 
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithGoogle() {
    const result = await FirebaseAuthentication.signInWithGoogle();
    return result;
  };

  /**
   * 
   * @returns The logged in user as a promise
   * Log user in using the Google Login Popover
   */
  async signInWithApple() {
    const result = await FirebaseAuthentication.signInWithApple({
      customParameters: [
        { key: "fullName", value: "true" }
      ]
    });
    return result;
  };

}
