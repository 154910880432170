import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  /** Which Country does this user belong to? */
  userCountry: any;

  constructor(
    private http: HttpClient
  ) { }

  checkNetworkStatus(): Observable<any> {
    console.log('checkNetworkStatus()');

    if (this.userCountry) {
      return of(this.userCountry);
    }

    return this.http.get('https://api.ipify.org?format=json').pipe(map((ipRes: any) => {
      if (ipRes.ip) {
        return this.http.get(`https://ipinfo.io/${ipRes.ip}?token=28e82edbee1247`)
      }
    }));
  }

  getShortAddress(lat, lng) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA6e-LhNCDdHIgvZkO7HP1J_WPHfiu43Es`);
  }
}

export interface IpAddressInfo {
  city: string;
  country: string;
  ip: string
  loc: string;
  org: string;
  postal: string;
  region: string;
  timezone: string;
}