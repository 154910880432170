import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AvatarComponent } from './avatar/avatar.component';

@NgModule({
    providers: [DatePipe],
    declarations: [
        AvatarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule
    ],
    exports: [
        AvatarComponent
    ]
})
export class ComponentsModule { }