import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  /** We're saving the Bearer Token in this variable.
   * This variable is assigned in the login component (login.component.ts)
   * We assign it from the return packet from dkc-login.
   */
  token: string;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  errorMessageContents = {
    title: undefined,
    message: undefined,
    button: undefined,
  };

  public runHttpCall(method, endpoint, contentType, body?): Observable<any> {
    const url = `${environment.uniTaskrApi.url}${endpoint}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.storageService.token}`,
    });
    switch (method) {
      case 'GET':
        return this.http.get<any>(url, { headers })
      case 'POST':
        return this.http.post<any>(url, body, { headers })
      case 'PATCH':
        return this.http.patch<any>(url, body, { headers })
      case 'PUT':
        return this.http.put<any>(url, body, { headers })
      case 'DELETE':
        return this.http.delete<any>(url, { headers })
    }
  }




}
