import { Component } from '@angular/core';
import { CountryService } from './services/country/country.service';
import { FirebaseService } from './services/firebase/firebase.service';
import countryToCurrency from 'country-to-currency';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'home' },
    { title: 'Error Logs', url: '/error-logs', icon: 'bug' },
    { title: 'Error Codes', url: '/errors-by-code', icon: 'bug' },
    { title: 'Page Visits', url: '/page-visits', icon: 'eye' },
    { title: 'SHOUT Incompleteness', url: '/shout-incomplete', icon: 'megaphone' },
    { title: 'Select API', url: '/set-api', icon: 'planet' },
  ];


  constructor(
    public firebaseService: FirebaseService,
    private countryService: CountryService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.firebaseService.startListeningToAuthStateChange();

    this.countryService.checkNetworkStatus().subscribe(contryRes => {
      contryRes.subscribe(resRes => {
        console.log('country res: ', resRes)
        this.countryService.userCountry = resRes;
        this.firebaseService.myCurrencyCode = countryToCurrency[resRes.country];
        console.log('Set currency code: ', this.firebaseService.myCurrencyCode);
      })
    })
  }
}
