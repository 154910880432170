// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCVjcDm-tqQ1nvVwJ0mASIOcFpFMOnPLmI",
    authDomain: "uniblaze.firebaseapp.com",
    projectId: "uniblaze",
    storageBucket: "uniblaze.appspot.com",
    messagingSenderId: "580103506279",
    appId: "1:580103506279:web:61bc6fc3f249a7949987c2",
    measurementId: "G-2B4QHEP61V"
  },
  uniTaskrUtilities: {
    webBaseUrl: "http://localhost:8100/",
    iosBaseUrl: "capacitor://localhost"
  },
  uniTaskrApi: {
    // url: "https://app.unitaskr.com/"
    // url: "https://appfinal.unidoshdev.com/"
    // url: "http://localhost:3000/json_placeholder/",
    // url: "https://api-dev.unitaskr.com/",
    url: "https://api-dev1.unitaskr.com/",
    // url: "https://api-stg.unitaskr.com/",

    cdnBaseUrl: "https://d3v5ng09h7jl33.cloudfront.net/",
    s3FileBaseUrl: "https://unitaskr-web-cdn.s3.eu-west-1.amazonaws.com/"
  },
  supabase: {
    supabaseUrl: 'https://mrunvhqcuwbblaptndri.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzNzg1MDk5NCwiZXhwIjoxOTUzNDI2OTk0fQ.yvXC63-YXhGiySvcKeONur7U3rPT3XlKxCavsg20ZWg'
  },
  api: 'https://j3x0ln9gj7.execute-api.ap-northeast-1.amazonaws.com/dev/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
