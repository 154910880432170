import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public token: string = '';
  public FirebaseToken: string = '';
  public firebaseUser;
  public uniTaskrUser;
  public userId: string = '';

  public imageUrl: string = '';
  public signUpEmail: string = '';

  constructor() { }

  set(key, value): Promise<any> {
    if (key == 'accessToken') {
      this.token = value;
    } else {
      return Storage.set({ key: key, value: value });
    }
  }

  get(key): Promise<any> {
    return Storage.get({ key: key });
  }

  getToken(): any {
    return Storage.get({ key: 'accessToken' });
  }

  setAndString(key, value): Promise<any> {
    return this.set(key, JSON.stringify(value));
  }

  async getAndParse(key): Promise<any> {
    let res = await Storage.get({ key: key });
    try {
      return JSON.parse(res.value)
    } catch {
      return res.value
    }
  }

  getAndParseObservable(key): Observable<any> {
    return from(Storage.get({ key: key }).then(res => {
      try {
        return JSON.parse(res.value)
      } catch {
        return res.value
      }
    }));
  }

  Remove(key): Promise<any> {
    return Storage.remove({ key: key });
  }

  empty(): Promise<any> {

    this.token = '';
    this.FirebaseToken = '';
    this.firebaseUser = undefined;
    this.uniTaskrUser = undefined;
    this.userId = '';

    this.imageUrl = '';
    this.signUpEmail = '';

    return Storage.clear();
  }
}
