import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { HttpService } from 'src/app/services/http/http.service';
import countryToCurrency from 'country-to-currency';
import { CountryService } from '../country/country.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private http: HttpService,
    private countryService: CountryService
  ) { }

  checkEmailExists(email: string) {
    return this.http.runHttpCall(
      "POST",
      "register/checkemailexists",
      "application/json",
      {
        Email: email
      }
    );
  }

  loginUser(email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/login",
      "application/json",
      {
        email: email
      }
    );
  }

  legacyLogin(email: string, password: string) {
    return this.http.runHttpCall(
      "POST",
      `api/auth/legacy/login`,
      "application/json", {
      email,
      password
    })
  }

  linkLogins(email: string, password: string, timeZone: string) {
    return this.http.runHttpCall(
      "POST",
      `api/auth/legacy/link-login`,
      "application/json", {
      email,
      password, 
      timeZone,
      currencyCode: countryToCurrency[this.countryService.userCountry.country],
    })
  }

  registerStudent(
    email: string,
    firstname: string,
    lastname: string,
    telephoneNumber: string,
    password: string,
    profilePicture64: string,
    socialProfilePicture64: string,
    verificationCode: string,
    hometown: string,
  ) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/register/student",
      "application/json",
      {
        alerts: "",
        appleAccessKey: "",
        dateOfBirth: "",
        deviceToken: "",
        deviceType: true,
        email: email,
        facebookAccessKey: "",
        firstName: firstname,
        googleAccessKey: "",
        graduationYear: 0,
        hasEmailAlerts: false,
        hasPushAlerts: false,
        homeTownLat: 0,
        homeTownLocation: "",
        homeTownlon: 0,
        hometown: hometown,
        lastName: lastname,
        mainLocation: "",
        mainLocationLat: 0,
        mainLocationLon: 0,
        mainLocationName: "",
        mobile: `${telephoneNumber}`,
        password: password,
        profilePictureBase64: profilePicture64,
        referralCode: "",
        securityEmailtoken: "",
        shouterAlertId: 0,
        shouterInstagram: "",
        socialProfilePictureUrl: socialProfilePicture64,
        study: "",
        studyType: "",
        studyYear: "",
        university: "",
        verificationCode: verificationCode,
        countryName: this.countryService.userCountry ? this.countryService.userCountry.region : "GB",
        countryCode: this.countryService.userCountry.country,
        curLocCountryCode: this.countryService.userCountry.country,
        currencyId: this.countryService.userCountry.country == "GB" ? 1 : 2,
        currencyCode: countryToCurrency[this.countryService.userCountry.country],
      }
    ).pipe(tap(res => {
      console.log('TAP TAP TAP TAP TAP');
      console.log(res);
      console.log('TAP TAP TAP TAP TAP');
    }));
  }

  registerBusiness(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    businessName: string,
    businessDesc: string,
    website: string,
    instagram: string,
    profilePic: string
  ) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/register/business",
      "application/json",
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        businessName: businessName,
        referralCode: "",
        mobile: "",
        googleAccessKey: "",
        facebookAccessKey: "",
        appleAccessKey: "",
        socialProfilePictureUrl: profilePic,
        profilePictureBase64: "",
        verificationCode: "",
        deviceToken: "",
        deviceType: true,
        securityEmailtoken: "",
        instagramName: instagram,
        website: website,
        whoAreYou: businessDesc,
        dateOfBirth: "",
        hometown: "",
        homeTownLocation: "",
        homeTownLat: 0,
        homeTownlon: 0,
        mainLocation: "",
        mainLocationName: "",
        mainLocationLat: 0,
        mainLocationLon: 0,
        countryName: this.countryService.userCountry.region,
        countryCode: this.countryService.userCountry.country,
        curLocCountryCode: this.countryService.userCountry.country,
        currencyId: this.countryService.userCountry.country == "GB" ? 1 : 2,
        currencyCode: countryToCurrency[this.countryService.userCountry.country],
      }
    ).pipe(tap(res => {
      console.log('Bus Bus Bus Bus Bus');
      console.log(res);
      console.log('Bus Bus Bus Bus Bus');
    }));
  }

  registerPublic(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    profilePic: string
  ) {

    console.log('registerPublic()')
    // return this.countryService.checkNetworkStatus().pipe(map((countryRes) => {

    console.log('countryRes: ', this.countryService.userCountry);

    return this.http.runHttpCall(
      "POST",
      "api/auth/register/public",
      "application/json",
      {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        referralCode: "",
        mobile: "",
        googleAccessKey: "",
        facebookAccessKey: "",
        appleAccessKey: "",
        socialProfilePictureUrl: profilePic,
        profilePictureBase64: "",
        verificationCode: "",
        deviceToken: "",
        deviceType: true,
        securityEmailtoken: "",
        dateOfBirth: "",
        hometown: "",
        homeTownLocation: "",
        homeTownLat: 0,
        homeTownlon: 0,
        mainLocation: "",
        mainLocationName: "",
        mainLocationLat: 0,
        mainLocationLon: 0,
        countryName: this.countryService.userCountry.region,
        countryCode: this.countryService.userCountry.country,
        curLocCountryCode: this.countryService.userCountry.country,
        currencyId: this.countryService.userCountry.country == "GB" ? 1 : 2,
        currencyCode: countryToCurrency[this.countryService.userCountry.country],
      }
    ).pipe(tap(res => {
      console.log('Public Public Public Public Public');
      console.log(res);
      console.log('Public Public Public Public Public');
    }));

  }

  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));


    console.log('JWT Payload: ', jsonPayload);

    return JSON.parse(jsonPayload);
  }

  verifyStudentEmailSend(email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/verification/email/send",
      "application/json",
      {
        email: email
      }
    )
  }

  verifyStudentCheckCode(code: string, email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/verification/email/check",
      "application/json",
      {
        email: email,
        code: code
      }
    )
  }

  verifyManually(attachment: string) {
    return this.http.runHttpCall(
      "POST",
      "api/verification/manual",
      "application/json",
      {
        attachment: attachment,
      }
    )
  }

  legacyForgotPassword(email: string) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/legacy/forgot-password",
      "application/json",
      {
        email: email
      }
    )
  }

  submitLegacyReset(resetReq) {
    return this.http.runHttpCall(
      "POST",
      "api/auth/legacy/forgot-password/submit",
      "application/json",
      resetReq
    )
  }
}


export interface userRegistrationResponse {
  data: {
    email: string | null,
    firstName: string | null,
    fromUserId: number,
    fromUserProfilePic: string | null,
    message: string | null,
    messageId: number,
    notificationId: number,
    serviceOfferId: number,
    signalRMessage: string | null,
    studentServiceId: number,
    title: string | null,
    toUserId: string | null,
  },
  hasCompletedFirstSetup: boolean,
  success: boolean,
  token: string
}